import {Component, Inject, Vue} from "vue-property-decorator";
import DfStore from "@/vue/domain/store/df-store";
import Swal from "sweetalert2";
import {CONTAINER_TYPES} from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";

@Component
export default class DfPageEmailChangeComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;
  private loading = false;

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  get loginMail(): string {
    return this.$store.getters.loginMail;
  }

  set loginMail(loginMail: string) {
    this.$store.dispatch("setLoginMail", loginMail);
  }

  get showLogin(): boolean {
    return this.$store.getters.showLogin;
  }

  set showLogin(showLogin: boolean) {
    this.$store.dispatch("setShowLogin", showLogin);
  }

  beforeMount() {
    const activationToken: string = <any>this.$route.query.token || null;
    const emailToken: string = <any>this.$route.query.email || null;

    if (activationToken) {
      this.loading = true;
      this.service
        .activeUser({ activationToken: activationToken })
        .then((response: any) => {
          this.loading = false;
          Swal.fire({
            title: this.$t("df-page-home.modal.account-activation.title").toString(),
            text: this.$t("df-page-home.modal.account-activation.body").toString(),
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-page-home.modal.account-activation.ok").toString(),
          }).then((result) => {
            if (result.value) {
              this.loginMail = <any>this.$route.query.email || null;
              if (this.currentStore) {
                this.$router.push({name: "home-store", params: {storeAlias: this.currentStore.alias}})
                  .then((result: any) => {
                    this.showLogin = true;
                  })
                  .catch((err) => err);
              } else {
                this.$router.push({ name: "store-selection" });
              }
            }
          });
        })
        .catch((error: Error) => {
          this.loading = false;
          Swal.fire({
            title: this.$t("df-page-home.modal.account-activation.error.title").toString(),
            text: this.$t("df-page-home.modal.account-activation.error.body").toString(),
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-page-home.modal.account-activation.error.ok").toString(),
          }).then((result) => {
            if (result.value) {
              if (this.currentStore) {
                this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } }).catch((err) => err);
              } else {
                this.$router.push({ name: "store-selection" });
              }
            }
          });
        });
    } else if (emailToken) {
      this.loading = true;
      this.service
        .activeUser({ activationToken: emailToken })
        .then((response: any) => {
          this.loading = false;
          this.$root.$emit("logout", () => {
            this.$root.$emit("login");
          });
          Swal.fire({
            title: this.$t("df-page-home.modal.email-change.title").toString(),
            text: this.$t("df-page-home.modal.email-change.body").toString(),
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-page-home.modal.email-change.ok").toString(),
          }).then((result) => {
            if (result.value) {
              this.loginMail = <any>this.$route.query.email || null;
              this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } })
                .then((result: any) => {
                  this.showLogin = true;
                })
                .catch((err) => err);
            }
          });
        })
        .catch((error: Error) => {
          this.loading = false;
          Swal.fire({
            title: this.$t("df-page-home.modal.email-change.error.title").toString(),
            text: this.$t("df-page-home.modal.email-change.error.body").toString(),
            icon: "info",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-page-home.modal.email-change.error.ok").toString(),
          }).then((result) => {
            if (result.value) {
              this.$router.push({ name: "home-store", params: { storeAlias: this.currentStore.alias } }).catch((err) => err);
            }
          });
        });
    }
  }
}
